<template>
  <div id="searchNav" class="container-fluid bg-light">
    <div class="row pt-4 pb-4">
      <div class="col-2 text-center">
        <router-link :to="'/'"><a data-bs-dismiss="modal"><i class="fas fa-chevron-left"></i></a></router-link>
      </div>
      <div class="col-8 text-center">
        <i class="fa fa-laptop" aria-hidden="true"></i>
        <i class="fa fa-microchip" aria-hidden="true"></i>
        <i class="fas fa-memory"></i>
        <i class="fas fa-hdd"></i>{{ this.counterRam }}
      </div>
      <div class="col-2 text-center">
        <i class="fa fa-heart-o" aria-hidden="true"></i>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-4 p-4">
        <button
          type="button"
          class="btn btn-outline-secondary w-100"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="col-4 p-4">
        <!--<i class="fas fa-filter"></i>-->
      </div>
      <div class="col-4 p-4">
        <button
          type="button"
          class="btn btn-outline-secondary w-100"
          data-bs-toggle="modal"
          data-bs-target="#sortModal"
        >
          <i class="fas fa-sort"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavSearch",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  data() {
    return {
      hardware: "Computer",
      counterStorage: 32,
      counterRam: 2,
      quickSearchCompStyle: "",
      quickSearchProcessor: "",
      quickSearchRAM: 0,
      quickSearchStorage: 0,
      quickSearchTouch: 0,
      quickSearchDedicatedGraphics: 0,
      quickSearchDigitalStylus: 0,
      quickSearchMinPrice: 0,
      quickSearchMaxPrice: 100000,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>